import { Container } from "react-bootstrap";
import I18n from "../Config/I18n";
import { getLanguage } from "../helpers/auth";
import { FC, useState } from "react";
import RHF from "../components/reactHookForm";
import useMutationWithToast from "src/hooks/useMutationWithToast";
import { POST } from "src/helpers/requests";
import { useMutation, UseMutationResult } from "react-query";
import { useHistory } from "react-router-dom";
import { Url } from "src/Config/url";

const l = getLanguage();

export default function RecoverPassword() {
  const [email, setEmail] = useState("");

  return (
    <div className="page-image">
      <div className="mt-10 page-recover">
        <Container className="container-recover">
          <img className="mb-8 logo-sign" src="../logo.png" alt="logo" />
          {email ? <PasswordResetContainer email={email} /> : <EmailVerificationContainer setEmail={setEmail} />}
        </Container>
      </div>
    </div>
  );
}

const EmailVerificationContainer = ({ setEmail }) => {
  const [isError, setIsError] = useState(false);

  const verifyEmail: (values: { email: string }) => Promise<{
    data: any;
    error: any;
  }> = async values => {
    const data = {
      email: values.email,
      lang: "en",
    };
    try {
      const resp = await POST("/user/resetpassword", data);
      
      // If we have an error response, throw it
      if (resp?.error) {
        const errorMessage = resp.error.message || "Failed to reset password";
        console.error("Reset password error:", resp.error);
        throw new Error(errorMessage);
      }
      
      // If we don't have data or email in the response
      if (!resp?.data) {
        throw new Error("Invalid server response");
      }
      
      resp.data.email = values.email;
      return resp.data;
    } catch (error) {
      console.error("Reset password error:", error);
      throw error;
    }
  };

  const mutation = useMutation("EmailVerification", verifyEmail, {
    onSuccess: (data: any) => {
      setEmail(data.email);
    },
    onError: (error: any) => {
      console.error("Mutation error:", error);
      setIsError(true);
    },
  });
  

  return (
    <RHF onSubmit={values => mutation.mutate(values)} className="flex flex-col gap-y-3">
      <EmailVerification isError={isError} mutation={mutation} />
    </RHF>
  );
};

const EmailVerification: FC<{ isError: boolean; mutation: UseMutationResult }> = ({ isError, mutation }) => (
  <>
    <h3 className="text-recover">{I18n.t("recoverPassword.insertEmail", { locale: l })}</h3>
    <div>
      <RHF.InputOrange
        name="email"
        label={I18n.t("recoverPassword.recoverPassword", { locale: l })}
        placeholder={I18n.t("recoverPassword.insertEmailPlaceholder", { locale: l })}
        options={{ required: true, validate: email => /^[a-zA-Z0-9.!#$%&’*+\/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(email) }}
        autoComplete="email"
      />
      {isError && <span className="text-sm text-red-500">Unknown email</span>}
    </div>
    <div className="flex items-center justify-end gap-x-4">
      <a style={{ color: "rgb(230, 126, 34)" }} className="text-center forgot-password back-to-login" href="/">
        {I18n.t("recoverPassword.backLogin", { locale: l })}
      </a>
      <RHF.InputOrange value={I18n.t("recoverPassword.recoverButton", { locale: l })} query={mutation} type="submit" />
    </div>
  </>
);

const PasswordResetContainer: FC<{ email: string }> = ({ email }) => {
  const history = useHistory();
  const [isError, setIsError] = useState(false);
  const mutation = useMutationWithToast(
    "PasswordReset",
    async (values: { oneTimeCode: string; newPassword: string }) => {
      const data = {
        email: email,
        code: values.oneTimeCode,
        password: values.newPassword,
      };

      const url = "/user/setpassword";
      const resp = await POST(url, data);

      if (resp?.error) throw new Error("Unknown error");
      return resp.data;
    },
    {
      onSuccess: () => {
        history.push(Url.dashboard);
        window.location.reload();
      },
      onError: () => setIsError(true),
    }
  );

  return (
    <RHF onSubmit={values => mutation.mutate(values)} className="flex flex-col gap-y-3">
      <PasswordReset isError={isError} mutation={mutation} />
    </RHF>
  );
};

const PasswordReset: FC<{ isError: boolean; mutation: UseMutationResult }> = ({ isError, mutation }) => (
  <>
    <RHF.InputOrange
      label={I18n.t("recoverPassword.updatePassword", { locale: l })}
      name="oneTimeCode"
      placeholder={I18n.t("recoverPassword.emailCodePlacholder", { locale: l })}
      options={{ required: true }}
    />
    {isError && <span className="text-sm text-red-500">Invalid entries</span>}
    <RHF.InputOrange
      type="password"
      name="newPassword"
      placeholder={I18n.t("recoverPassword.newPassword", { locale: l })}
      options={{ required: true, minLength: 6 }}
      autoComplete="new-password"
    />
    <div className="flex items-center justify-end gap-x-4">
      <a style={{ color: "rgb(230, 126, 34)" }} className="text-center forgot-password back-to-login" href="/">
        {I18n.t("recoverPassword.backLogin", { locale: l })}
      </a>
      <RHF.InputOrange value="Login" type="submit" query={mutation} />
    </div>

  </>
);

